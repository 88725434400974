<template>
    <div>
        <div style="display: flex;align-items:center;font-size: 20px;justify-content: center; width: 80%;margin: 4px auto;">
            <div>Start Time : {{ start }}</div>
            <div style="margin-left:26px">End Time : {{ end }}</div>
            <el-button style="margin-left:26px" @click="check" type="primary">Play</el-button>
        </div>
        <video class="myVideo" src="https://mainbucket.learningfirst.cn/Lecture%20on%20UHI.mp4" ref="video"></video>
    </div>
</template>

<script>
import { recordPlay, checkPlayTime,getProjectInfo} from '../../api/index'
import { getUserId } from '../../utils/store'
export default {
    data(){
        return{
            timer:null,
            video:null,
            start:'',
            end:''
        }
    },
    mounted(){
        this.video = this.$refs.video
        this.getProject()
    },
    destroyed(){
        window.clearInterval(this.timer)
    },
    methods:{
        getProject(){
            getProjectInfo('22-23Envirothon_SG').then(res=>{
                this.start = res.data.lecture_starttime
                this.end = res.data.lecture_endtime
            })
        },
        check(){
            checkPlayTime({user_id:getUserId(),project_code:'22-23Envirothon_SG'}).then(res=>{
                if(res.data.code===0){
                    if(this.video.paused){
                        this.video.currentTime = res.data.data
                        this.video.play()
                        this.startRecord()
                    }
                }else{
                    this.$message.error(res.data.msg)
                }
            })
        },
        startRecord(){
            this.timer = setInterval(()=>{
                recordPlay({user_id:getUserId(),watch_to_time:this.video.currentTime})
            },5000)
        },
        
    }
}
</script>

<style lang="scss" scoped>
.myVideo{
    width: 100%;
    margin:0 auto;
}
</style>